/*globals define, document, window, $*/

import "../plugin/watchlistlayer";
import anchor from "./anchor";

var iframeHeight;

export default {
  init: function() {
    let _this = this;
    _this.initWishlist();
    _this.initSlider();
    _this.initEventTicketBuyButton();
    _this.initEventTicketWorkaroundBuyButton();
    _this.initIframeObserver();
  },

  initWishlist: function() {
    $(".js_add-to-watchlist").SnsWatchlistLayer();
  },

  initSlider: function() {
    const eventImagesSlider = document.querySelector(".event-images-slider");
    const itemsElement = eventImagesSlider.querySelector(".items");

    itemsElement
      .querySelectorAll(".item > .event-image-item")
      .forEach(eventImageItemElement => {
        const image = new Image();

        image.addEventListener("error", () => {
          eventImageItemElement.parentElement.remove();
        });
        image.addEventListener("load", () => {
          const eventImageElement = eventImageItemElement.querySelector(
            ".event-image"
          );
          eventImageElement.style.backgroundImage = `url(${eventImageItemElement.dataset.image})`;

          if (eventImageItemElement.classList.contains("selected")) {
            const mainImageElement = document.querySelector(
              ".event-main-image"
            );
            mainImageElement.style.backgroundImage = `url(${eventImageItemElement.dataset.largeImage})`;

            const anchor = mainImageElement.querySelector("a");
            anchor.href = eventImageItemElement.dataset.largeImage;
          }
        });
        image.src = eventImageItemElement.dataset.image;

        eventImageItemElement.addEventListener("click", () => {
          if (eventImageItemElement.classList.contains("selected")) {
            return;
          }

          const selectedEventImageItemElement = eventImagesSlider.querySelector(
            ".items .item > .event-image-item.selected"
          );
          selectedEventImageItemElement.classList.remove("selected");

          eventImageItemElement.classList.add("selected");
          const mainImageElement = document.querySelector(".event-main-image");
          mainImageElement.style.backgroundImage = `url(${eventImageItemElement.dataset.largeImage})`;

          const anchor = mainImageElement.querySelector("a");
          anchor.href = eventImageItemElement.dataset.largeImage;
        });
      });
  },

  initEventTicketBuyButton: function() {
    $(document).on("click", ".js-event-ticket-layer", function(e) {
      e.preventDefault();

      let $button = $(this);
      let layerWidth = window.outerWidth * 0.6;
      let layerHeight = window.innerHeight;
      if (window.outerWidth <= 802) {
        layerWidth = window.outerWidth;
        layerHeight = window.outerHeight;
      }
      lightcase.start({
        href: $button.attr("data-href"),
        showSequenceInfo: false,
        type: "ajax",
        maxWidth: 1000,
        maxHeight: layerHeight,
        ajax: {
          width: layerWidth,
          height: layerHeight
        },
        shrinkFactor: 1,
        onFinish: {
          injectContent: function() {
            lightcase
              .get("contentInner")
              .children()
              .css({
                height: layerHeight,
                overflowY: "scroll"
              });
            EventMgr_writeSignupForm("emoSignupCustom");
          }
        }
      });
    });
  },

  initEventTicketWorkaroundBuyButton: function() {
    $(document).on("click", ".js-event-ticket-fold", function(e) {
      e.preventDefault();

      $("#emoSignup").show();

      let $button = $(this),
        $target = $button.attr("data-target");
      anchor.triggerAnchor($target, null, false);
    });
  },

  initIframeObserver: function() {
    $(document).ready(function() {
      const iframe = document.getElementById("id_eventmgr_if");
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === "style") {
            const newHeight = iframe.style.height;

            // Execute action when the height changes
            if (newHeight) {
              if (iframeHeight) {
                setTimeout(function() {
                  anchor.triggerAnchor("emoSignup", null, false);
                }, 500);
              }
              iframeHeight = newHeight;
            }
          }
        });
      });

      // Start observer for the iframe
      observer.observe(iframe, { attributes: true });
    });
  }
};
